import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HttpService from './services/HttpService';
import './CreateForm.css';
import moment from 'moment';

import { Layout, Row, Col, Table, Card, Empty } from 'antd';
import { InstagramOutlined, FacebookOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;

interface Data {
}

interface DataSourceItem {
  name: string;
  label: string;
}

const Home: React.FC = () => {
  const { code } = useParams();
  const [data, setData] = useState<Data | null>(null);
  const [dataSource, setDataSource] = useState<DataSourceItem[]>([]);
  const httpService = new HttpService();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await httpService.getSertifikasiByCode(code!);
        setData(response);

        const mappedDataSource = mapApiResponseToDataSource(response);
        setDataSource(mappedDataSource);
      } catch (error) {
        setData(null);
        setDataSource([]);
      }
    };

    fetchData();

    return () => {
    };
  }, []);

  const mapApiResponseToDataSource = (apiResponse: any): DataSourceItem[] => {
    const formatDate = (dateString: string | undefined): string => {
      return dateString ? moment(dateString).format('DD MMM YYYY') : '';
    };

    const formatPercentage = (value: string | undefined): string => {
      if (value && !isNaN(parseFloat(value))) {
        const percentage = (parseFloat(value) * 100).toFixed(2);
        return `${parseFloat(percentage)}%`;
      }
      return value || '';
    };

    const getYear = (date: string | undefined): string => {
      if(!date) return '';

      // Check if the date is in the ISO format
      const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    
      if (isoDateRegex.test(date)) {
        // Extract and return the year from the ISO date
        return new Date(date).getUTCFullYear().toString();
      } else if (/^\d{4}$/.test(date)) {
        // Return the year if the date is already in the year format
        return date;
      } else {
        // Handle unexpected date formats if necessary
        throw new Error('Invalid date format');
      }
    };

    return [
      { name: apiResponse.sertifikasi.noBerkas, label: 'No Berkas' },
      { name: apiResponse.sertifikasi.noLot, label: 'No LOT' },
      { name: apiResponse.sertifikasi.noInduk, label: 'No. Induk' },
      { name: apiResponse.sertifikasi.produsen, label: 'Produsen' },
      { name: apiResponse.sertifikasi.alamat, label: 'Alamat' },
      { name: apiResponse.sertifikasi.jenisTanaman, label: 'Jenis Tanaman' },
      { name: apiResponse.sertifikasi.varietas, label: 'Varietas' },
      { name: apiResponse.sertifikasi.kelasBenih, label: 'Kelas Benih' },
      { name: formatDate(apiResponse.sertifikasi.tanggalPanen), label: 'Tanggal Panen' },
      { name: apiResponse.sertifikasi.beratKemasan, label: 'Berat Kemasan' },
      { name: apiResponse.sertifikasi.jumlahStokBenih || '', label: 'Jumlah Stok Benih' },
      { name: formatDate(apiResponse.sertifikasi.tanggalSelesaiUji), label: 'Tanggal Selesai Uji' },
      { name: formatDate(apiResponse.sertifikasi.tanggalAkhirMasaEdar), label: 'Tanggal Akhir Masa Edar' },
      { name: apiResponse.sertifikasi.noSeriAwal, label: 'No Seri Awal' },
      { name: apiResponse.sertifikasi.noSeriAkhir, label: 'No Seri Akhir' },
      { name: formatPercentage(apiResponse.sertifikasi.kadarAir), label: 'Kadar Air' },
      { name: formatPercentage(apiResponse.sertifikasi.dayaBerkecambah), label: 'Daya Berkecambah' },
      { name: formatPercentage(apiResponse.sertifikasi.benihMurni), label: 'Benih Murni' },
      { name: formatPercentage(apiResponse.sertifikasi.kotoranBenih), label: 'Kotoran Benih' },
      { name: formatPercentage(apiResponse.sertifikasi.cvlLapang), label: 'CVL Lapang' },
      { name: formatPercentage(apiResponse.sertifikasi.gulma), label: 'Benih Tanaman Lain/Gulma' },
      { name: apiResponse.sertifikasi.benihWarnaLain || '', label: 'Benih Warna Lain' },
      { name: getYear(apiResponse.sertifikasi.ta), label: 'TA' }
    ];
  };

  const handleInstagramClick = () => {
    const url = 'https://instagram.com/benih.r7';
    window.open(url, '_blank');
  };

  const handleFacebookClick = () => {
    const url = 'https://www.facebook.com/Benih-R7-Restu-101949972239803/';
    window.open(url, '_blank');
  };

  const handleShopeeClick = () => {
    const url = 'https://shopee.co.id/benihr7official';
    window.open(url, '_blank');
  };

  const handleTokpedClick = () => {
    const url = 'https://www.tokopedia.com/benihr7official?source=universe&st=product';
    window.open(url, '_blank');
  };

  return (
    <div>
      <Layout>
        <Header style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', backgroundColor: '#006530' }}>
          <div style={{ display: 'flex', alignItems: 'center', color: '#fff', }}>
            <span style={{ marginRight: 10 }}>Follow Us: </span>
            <InstagramOutlined
              style={{ fontSize: 20, marginRight: 10, cursor: 'pointer' }}
              onClick={handleInstagramClick}
            />
            <FacebookOutlined style={{ fontSize: 20 }} onClick={handleFacebookClick} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', color: '#fff', marginLeft: '5%' }}>
            <span style={{ marginRight: 10 }}>Buy at: </span>
            <img onClick={handleShopeeClick} src="https://benihraja.com/wp-content/uploads/2022/06/Shopee-ICon-white-01-271x300.png" alt="Shopee" style={{ width: '20px', height: 'auto', marginRight: '10px' }} />
            <img onClick={handleTokpedClick} src="https://benihraja.com/wp-content/uploads/2022/06/logo-tokopedia-icon-mascot-400x400-copy.png" alt="Tokopedia" style={{ width: '20px', height: 'auto' }} />
          </div>
        </Header>
      </Layout>

      {data ? (
        <Content className="site-layout" style={{ marginTop: 20 }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: '650px', display: 'flex', justifyContent: 'center' }}>
            <Card className='custom-card'>
              <Row justify={'center'}>
                <img style={{ width: '30%' }} src="https://benihraja.com/wp-content/uploads/2021/11/Screenshot-from-2021-11-05-19-01-51-1.png" alt="Logo" />
              </Row>
              <Row justify={'center'}>
                <p style={{ fontSize: 20, textAlign: 'center', fontFamily: 'serif' }}>Informasi Label <br></br> Benih Bersertifikat</p>
              </Row>
              <Table
                pagination={false}
                size="middle"
                dataSource={dataSource}
                rowKey="name"
                style={{ marginBottom: 20, marginTop: 20 }}
                showHeader={false}
              >
                <Table.Column width='50%' align='left' title="Field" dataIndex="label" key="label" render={(text) => (
                  <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{text}</span>
                )} />
                <Table.Column align='left' title="Input" dataIndex="name" key="name" render={(name) => (
                  <span style={{ fontSize: '16px' }}>: {name}</span>
                )} />
              </Table>
            </Card>
          </div>
        </Content>
      ) : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '650px' }}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
      }
    </div>
  );
}

export default Home;

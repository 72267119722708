import React from 'react';
import { Empty, Layout } from 'antd';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';

const { Content } = Layout;

const App: React.FC = () => {
  return (
    <Layout>
      <Router>
        <Routes>
          <Route path="/:code" element={<Home />} />
          <Route path="/" element={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '650px' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>} />
        </Routes>
      </Router>
    </Layout>
  );
}

export default App;

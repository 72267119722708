import axios from "axios";

const apiService = axios.create({
  baseURL: "https://api.benihraja.com",
});

class HttpService {
  async getSertifikasiByCode(code: string): Promise<any> {
    const response = await apiService.get(
      `/sertifikasi/find-by-code?code=${code}`
    );
    return response.data;
  }
}

export default HttpService;
